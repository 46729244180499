import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;
import wx from 'weixin-js-sdk';

import { List } from 'vant';

import 'vant/lib/index.css';

Vue.use(List);
Vue.use(wx);
// Vue.use(ElementUI);
// import '../src/assets/style/public.css'
// import '../src/assets/js/index.js'

// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()

// router.beforeEach((to, from, next) => {
//   var u = navigator.userAgent;
//   var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
//   if (isiOS && to.path !== location.pathname) {
//     location.assign(to.fullPath); // 此处不可使用location.replace
//   } else if (u.indexOf("miniProgram")) {
//     location.assign(to.fullPath); // 此处不可使用location.replace
//   } else {
//     next();
//   }
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");